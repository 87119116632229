import { render, staticRenderFns } from "./LoginFields.vue?vue&type=template&id=2b28e40c&scoped=true&"
import script from "./LoginFields.vue?vue&type=script&lang=js&"
export * from "./LoginFields.vue?vue&type=script&lang=js&"
import style0 from "./LoginFields.vue?vue&type=style&index=0&id=2b28e40c&prod&lang=scss&scoped=true&"
import style1 from "./LoginFields.vue?vue&type=style&index=1&id=2b28e40c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b28e40c",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QImg,QCardSection,QInput,QIcon,QCardActions,QBtn});
