<template>
	<div class="flex justify-center" v-if="is_ready">
		<q-card
			:class="$q.screen.gt.xs ? 'q-pa-md q-mt-xl' : ''"
			:style="$q.screen.gt.xs ? 'width: 700px; max-width: 80vw' : 'width: 100%;'"
		>
			<div class="img-wrapper">
				<q-img
					:src='img_path'
					height="50px"
				/>
			</div>
			<q-card-section @keyup.enter="handleSubmit">
				<q-input
					v-if="!custom_login"
					outlined
					bottom-slots
					v-model="company_id"
					:hint='field_text'
					name="company_id"
					type='text'
					class="q-mb-lg"
					color="primary"
					label="Company ID"
					:error="company_id_error"
					:error-message="company_id_error_message"
				>
				</q-input>
				<q-input
					outlined
					name="email"
					bottom-slots
					v-model="email"
					class="q-mb-lg"
					label="Email"
					type="email"
					id='email'
					:error="email_error"
				>
					<template v-slot:error v-slot-scoped:errorMessage='{email_error_message}'>
						<p class="q-mb-xs" v-for="message in email_error_message.split('\n')" :key="message">
							{{message}}
						</p>
					</template>
				</q-input>
				<q-input
					outlined
					class="q-mb-lg"
					label="Password"
					bottom-slots
					v-model="password"
					:type="isPwd ? 'password' : 'text'"
					:error="password_error"
					:error-message="password_error_message"
					:hint='field_text'
				>
					<template v-slot:append>
						<q-icon
							:name="isPwd ? 'visibility_off' : 'visibility'"
							class="cursor-pointer"
							@click="hide()"
						/>
					</template>
				</q-input>
				<q-input
					v-if="$route.name == 'Login User 2FA'"
					outlined
					class="q-mb-lg"
					label="OTP"
					bottom-slots
					v-model="otp_token"
					:hint='field_text'
					:error="otp_token_error"
					:error-message="otp_token_error_message"
				>
				</q-input>
				<q-card-actions class="q-mt-md q-px-none">
					<q-btn
						v-if="$route.name!='Login User 2FA'"
						push
						color='primary'
						background-color='primary'
						class="login-btn full-width"
						@click="checkFor2FA"
						label="LOG IN"
					/>
					<q-btn
						v-else
						push
						color='primary'
						background-color='primary'
						class="login-btn full-width"
						@click="login"
						label="LOG IN"
					/>
					<div class="q-mt-sm row justify-center full-width text-caption">
						<div style="float:right;">
							<router-link
								:to="{ name: 'Reset Password' }"
								class="q-pr-sm"
							>
								<label class="link-label">Reset password</label>
							</router-link>
						</div>
						<div class="text-right">
							<router-link item :to="{name: 'Can`t Log In'}" class="q-pl-sm">
								<label class="link-label">Can`t log in?</label>
							</router-link>
						</div>
					</div>
				</q-card-actions>
				<div class="row text-style">
					<p class="col-xs-12 q-mb-xs">
						<q-icon class="material-icons" color='primary'>email</q-icon>
						{{company_email}}
					</p>
					<p class="col-xs-12 q-mb-sm">
						<q-icon class="material-icons" color='primary'>phone</q-icon>
						{{company_phone_number}}
					</p>
				</div>
				<div class="flex justify-between text-caption text-style">
					<span>
						<span class="q-mr-xs"> © Alert Cascade Limited </span>
						<span class="q-mx-xs">|</span>
						<span class="q-mx-xs"><a target="_blank" href="https://alertcascade.co.uk/privacy">Privacy </a></span>
						<span class="q-mx-xs">|</span>
						<span class="q-mx-xs"><a target="_blank" href="https://alertcascade.co.uk/terms-of-service"> Terms of Service </a></span>
					</span>
					<span class="flex justify-end text-caption"> Version {{version}} </span>
				</div>
			</q-card-section>
		</q-card>
	</div>
</template>
<script>
import LoginAPI from '@/services/api/Login.js';
import authAPI from '@/services/api/auth.js';
import router from "@/router";
import { mapActions } from 'vuex'
import { colors } from 'quasar'
export default({
	name: 'LoginFields',
	components: {
		LoginAPI,
		authAPI,
		router,
	},
	props: {
		custom_login: Boolean,
		brand: String,
	},
	data() {
		return {
			email_error: false,
			password_error: false,
			company_id_error: false,
			email_error_message: null,
			password_error_message: null,
			company_id_error_message: null,
			company_phone_number: '',
			company_email: '',
			version: '',
			branding: {},
			img_path: '',
			is_ready: false,
			isPwd: true,
			company_id: '',
			email: '',
			password: '',
			otp_token: '',
			otp_token_error: false,
			otp_token_error_message: ''
		}
	},

	methods: {
		...mapActions(['set_active_item']),
		isFieldValid(){
			let message = 'This field is required.'
			let is_valid = true
			if (!this.company_id){
				is_valid = true
				this.company_id_error = true
				this.company_id_error_message += message + '\n'
			}
			if (!this.email){
				is_valid = true
				this.email_error = true
				this.email_error_message += message + '\n'
			}
			if (!this.password){
				is_valid = true
				this.password_error = true
				this.password_error_message += message + '\n'
			}
			if (this.$route.name == '2FA Login') {
				if (!this.otp_token){
					is_valid = true
					this.otp_token_error = true
					this.otp_token_error_message += message + '\n'
				}
			}
			return is_valid
		},
		resetValues(){
			this.company_id_error = this.email_error = this.password_error = this.otp_token_error = false
			this.company_id_error_message = this.email_error_message = this.password_error_message = this.otp_token_error_message = ''
		},
		async login(){
			this.resetValues()
			if (!this.isFieldValid()){
				return
			}
			let user_data = {
				'customer_group': this.company_id,
				'email': this.email,
				'password': this.password,
				'otp_token': this.otp_token
			}
			const response = await LoginAPI.loginUser(user_data)
			if (response.error) {
				if (Object.keys(response.error_message) == 'required'){
					this.email_error = true
					for (let message of Object.values(response.error_message)){
						this.email_error_message += message + '\n'
					}
					return
				}
				this.$q.notify({
					timeout: 6700,
					message: Object.values(response.error_message),
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$router.push({ name: "Dashboard" })
			this.set_active_item(1);
		},
		async checkFor2FA() {
			let user_data = {
				'customer_group': this.company_id,
				'email': this.email,
				'password': this.password
			}
			let is_valid = this.isFieldValid()
			if (!is_valid){
				return
			}
			const response = await LoginAPI.checkFor2FAPermission(user_data)
			if (response.requires_2FA) {
				this.$router.push({'name': 'Login User 2FA'})
			} else {
				this.login()
			}
		},
		handleSubmit() {
			if (this.$route.name != 'Login User 2FA') {
				this.checkFor2FA()
				return
			}
			this.login()
		},
		hide() {
			this.isPwd = !this.isPwd
			setTimeout(() => {
				this.isPwd = !this.isPwd
			}, 10000);
		}
	},
	async created(){
		this.field_text = 'Please remember that this field is case-sensitive'
		let company_info = await authAPI.getVersionNumber()
		this.version = company_info.data.version_number
		if (this.custom_login){
			await LoginAPI.getBranding(this.brand).then((response) => {
				if (response.data['login_company_id']){
					this.company_id = response.data['login_company_id']
				}else{
					this.$router.push({ name: "Login User" })
				}
				this.company_phone_number = response.data['brand_phone']
					? response.data['brand_phone'] : company_info.data.company_phone_number
				this.company_email = response.data['brand_email']
					? response.data['brand_email'] : company_info.data.company_email
				this.img_path = response.data['brand_logo']
				let primary_color = response.data['highlight_color'] ? response.data['highlight_color'] : '#1976d2'
				colors.setBrand('primary', primary_color)
				this.is_ready = true;
			})
		}else{
			this.company_phone_number = company_info.data.company_phone_number
			this.company_email = company_info.data.company_email
			this.img_path = '/static/images/logo.png'
			colors.setBrand('primary', '#1976d2')
			this.is_ready = true;
		}
	}
})
</script>
<style lang="scss" scoped>
	@media (max-width: $breakpoint-sm-min){
		p, a, span {
			font-size: 12px;
		}
	}
</style>
<style>
	.img-wrapper .q-img__image{
		background-size:auto 100%!important;
	}
	a{
		cursor: pointer;
		color: #000;
		text-decoration: underline;
	}
	.link-label{
		cursor: pointer;
	}
@media screen and (max-width: 768px) {
	.text-style{
		text-align: center;
		justify-content: center;
	}
}
</style>
